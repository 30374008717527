
import { defineComponent } from "vue";
import InputText from "primevue/inputtext";
import Button from "primevue/button";
import { mapActions, mapGetters } from "vuex";
import MessageBox from "@/components/MessageBox.vue";

// Services
import ScanService from "@/services/ScanService";
import WorkOrderService from "@/services/WorkOrderService";

const scanService = new ScanService(process.env.VUE_APP_ABSTRACTION_API);

const workOrderService = new WorkOrderService(
  process.env.VUE_APP_ABSTRACTION_API
);

export default defineComponent({
  name: "Details",
  components: {
    InputText,
    Button,
    MessageBox,
  },
  data() {
    return {
      shopTransactionPayload: {
        emp_id: "",
        wo_id: "",
        model: "",
        quantity: "",
        from_oper: "",
        from_wc: "",
        from_desc: "",
        to_oper: "",
        to_wc: "",
        to_desc: "",
        user_id: "",
      },
      workOrder: {} as any,
      errorMessage: "",
      showErrorDialog: false,
      focusRefName:"",
      rowClass:
        "p-field p-col col-12 m-0 p-0 pb-1 flex justify-content-center r-mono p-input-icon-right",
    };
  },
  computed: {
    ...mapGetters({
      getClient: "session/getClient",
      getUser: "session/getUser",
    }),
  },
  methods: {
    ...mapActions({
      addNotification: "notification/add",
    }),
    clearAll() {
      this.shopTransactionPayload.emp_id = "";
      this.shopTransactionPayload.wo_id = "";
      this.shopTransactionPayload.model = "";
      this.shopTransactionPayload.quantity = "";
      this.shopTransactionPayload.from_oper = "";
      this.shopTransactionPayload.from_wc = "";
      this.shopTransactionPayload.from_desc = "";
      this.shopTransactionPayload.to_oper = "";
      this.shopTransactionPayload.to_wc = "";
      this.shopTransactionPayload.to_desc = "";
      this.shopTransactionPayload.user_id = this.getUser.user_id;

      this.workOrder = {};
    },
    focusInput(refName: string) {
      if (refName) {
        (this.$refs[refName] as any).$el.focus();
        (this.$refs[refName] as any).$el.select();
      }
    },
    processShopTransaction() {
      scanService.postShopTransaction(this.shopTransactionPayload).then(
        (resp: any) => {
          this.handleSuccessDialog(resp?.message || "Transaction processed successfully.");
          this.clearAll();
          this.focusInput("wo_id");
        },
        (err) => {
          this.handleError(err.response.data.error || "Error with processing transaction.");
        }
      );
    },
    handleEmployeeId() {
      if (!this.shopTransactionPayload.emp_id) {
        this.handleErrorAndFocus("Employee ID required.", "emp_id");
        return;
      }

      this.shopTransactionPayload.emp_id =
        this.shopTransactionPayload.emp_id.toUpperCase();
      this.focusInput("wo_id");
    },
    handleWorkOrder() {
      if (!this.shopTransactionPayload.wo_id) {
        this.handleErrorAndFocus("Work Order ID required.", "wo_id");
        return;
      }

      workOrderService.getWorkOrderById(
        this.getClient.client_id,
        this.shopTransactionPayload.wo_id,
        "", //"wo_id status model_number backflush",
        ""
      ).then((resp) => {
        this.workOrder = resp as any;
        const wo_id =  this.workOrder.wo_id;
        const status =  this.workOrder.status;
        const modelNumber =  this.workOrder.model_number || "";

        if(status === 'F') {
          throw Error(`Work Order ${wo_id} has been finalized. No update permitted.`)
        } else if(status === 'N' || status === 'O' || status === 'R') {
          throw Error(`Work Order ${wo_id} has not been pulled. No update permitted.`)
        }

        this.shopTransactionPayload.wo_id = wo_id;
        this.shopTransactionPayload.model = modelNumber;

        this.focusInput("quantity");
      }).catch((err) => {
        this.handleErrorAndFocus(err.message, "wo_id");
      })
    },
    handleModel() {
      this.focusInput("quantity");
    },
    handleQuantity() {
      if (!this.shopTransactionPayload.quantity) {
        this.handleErrorAndFocus("Quantity required.", "quantity");
        return;
      }
      this.focusInput("from_oper");
    },
    handleFromOperation() {
      if (!this.shopTransactionPayload.from_oper) {
        this.handleErrorAndFocus("From Operation required.", "from_oper");
        return;
      }

      this.shopTransactionPayload.from_oper =
        this.shopTransactionPayload.from_oper.toUpperCase();

      const operations = this.workOrder.oper_items;
      const operation = operations.find((oper: any) => {
        return oper.oper === this.shopTransactionPayload.from_oper
      });

      if (!operation) {
        this.handleErrorAndFocus(
          `${this.shopTransactionPayload.from_oper} does not appear as an operation on the routing.`,
          "from_oper"
        );
        return;
      }

      const from_work_center = operation.work_center || "";
      const from_desc = operation.operation_desc || "";

      this.shopTransactionPayload.from_wc = from_work_center;
      this.shopTransactionPayload.from_desc = from_desc;


      this.focusInput("to_oper");
    },
    handleFromWorkCenter() {
      this.focusInput("from_desc");
    },
    handleFromDesc() {
      this.focusInput("to_oper");
    },
    handleToOperation() {
      this.shopTransactionPayload.to_oper =
        this.shopTransactionPayload.to_oper.toUpperCase();

      const operations = this.workOrder.oper_items;
      const operation = operations.find((oper: any) => {
        return oper.oper === this.shopTransactionPayload.to_oper
      });

      if (!operation) {
        this.handleErrorAndFocus(
          `${this.shopTransactionPayload.to_oper} does not appear as an operation on the routing.`,
          "to_oper"
        );
        return;
      }

      const to_work_center = operation.work_center || "";
      const to_desc = operation.operation_desc || "";

      this.shopTransactionPayload.to_wc = to_work_center;
      this.shopTransactionPayload.to_desc = to_desc;

      this.focusInput("to_wc");
    },
    handleToWorkCenter() {
      this.focusInput("to_desc");
    },
    handleToDesc() {
      this.focusInput("to_desc");
    },
    handleExit() {
      this.$router.push("/scan");
    },
    handleError(message: string) {
      const notification = {
        dialog: true,
        type: "error",
        message,
      };
      this.addNotification(notification, { root: true });
    },
    handleMessage(message: string) {
      const notification = {
        dialog: true,
        type: "success",
        message,
      };
      this.addNotification(notification, { root: true });
    },
    handleErrorAndFocus(message: string, ref: string) {
      this.errorMessage = message;
      this.showErrorDialog = true;
      this.focusRefName = ref;
    },
    clickConfirmErrorDialog() {
      this.showErrorDialog = false;
      if (this.focusRefName) {
        this.focusInput(this.focusRefName);
      }
      this.focusRefName = "";
    },
    handleSuccessDialog(message: string) {
      const notification = {
        dialog: true,
        type: "success",
        message,
      };
      this.addNotification(notification, { root: true });
    },
  },
  mounted() {
    this.clearAll();
    this.shopTransactionPayload.user_id = this.getUser.user_id;
    this.focusInput("wo_id");
  },
});
